import { type LazyAsyncQueryOptions, useLazyAsyncQuery } from './useAsyncQuery';
import type { OperationVariables } from '@apollo/client';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';

/**
 * 認証が確認できてからクエリを実行するcomposable
 */
export const useAuthedQuery = <TResults, TVariables extends OperationVariables>(
  document: TypedDocumentNode<TResults, TVariables>,
  variables?: MaybeRefOrGetter<TVariables>,
  options?: LazyAsyncQueryOptions<TResults>
) => {
  const { execute, ...queryResult } = useLazyAsyncQuery(
    document,
    variables,
    options
  );

  const p = import.meta.client
    ? new Promise<typeof queryResult>((resolve) => {
        useAuthenticationState().then(({ isAuthenticated }) => {
          watch(
            isAuthenticated,
            async (isAuthenticated) => {
              if (!isAuthenticated) return;
              await execute();
              resolve(queryResult);
            },
            { immediate: true }
          );
        });
      })
    : (async (): Promise<typeof queryResult> => queryResult)();

  return {
    ...queryResult,
    then: (...params: Parameters<(typeof p)['then']>) => p.then(...params),
  };
};

/**
 * @deprecated
 *
 * useAuthedQuery が await できるようになって中身が一緒になったからそっち使って
 * ね
 *
 * awaitをつけて実行すると、認証が確認できるまでクエリを遅延する非同期な
 * composable
 */
export async function useAsyncAuthedQuery<
  TResults,
  TVariables extends OperationVariables,
>(
  query: TypedDocumentNode<TResults, TVariables>,
  variables?: MaybeRefOrGetter<TVariables>,
  opts?: LazyAsyncQueryOptions<TResults>
) {
  return useAuthedQuery(query, variables, opts);
}
